import logo from "./logo.png";
import "./App.css";

import { useEffect, useState } from "react";
import { createClient } from "@supabase/supabase-js";

import { saveAs } from "file-saver";
import { isMobile } from "react-device-detect";

function App() {
  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState([]);
  const [error, setError] = useState();
  const [downloading, setDownloading] = useState(false);

  const url = `${process.env.REACT_APP_SUPABASE_URL}/storage/v1`;

  useEffect(() => {
    getImages();
  }, []);

  async function getImages() {
    setLoading(true);
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token");

    const supabase = createClient(
      process.env.REACT_APP_SUPABASE_URL,
      process.env.REACT_APP_SUPABASE_KEY,
      {
        global: {
          headers: {
            "x-token": token,
          },
        },
      }
    );

    const { data, error } = await supabase
      .from("photo_delivery")
      .select(
        `
        id,
        images ( image_url, expire_date)
      `
      )
      .eq("token", token);

    if (!error) {
      setImages(data[0].images);
    } else {
      setError(error);
    }

    setLoading(false);
  }

  const downloadImage = (url, name) => {
    setDownloading(true);
    saveAs(url, name);
    setTimeout(() => setDownloading(false), 1000);
  };

  return (
    <div className="app">
      <header className="app-header">
        <img src={logo} className="logo" alt="logo" />
        <div class="divider">
          <div></div>
        </div>
        <h1>Father/Son Frisbee Golf 2023</h1>
      </header>
      {!loading && images.length > 0 ? 
        isMobile ? (
        <div class="instruction">*Long press each image and click 'Add to Photos'</div>
      ) : (
        <div class="instruction">*Click each image to download</div>
      ) : null}
      {loading ? (
        <div class="center">
          <span class="loader"></span>
        </div>
      ) : error ? (
        <div class="center">
          <div>
            Yikes! There was an error with this request. Please try again later
            or contact us at&nbsp;
            <a href="mailto:info@butlerdadsclub.org">info@butlerdadsclub.org</a>
          </div>
        </div>
      ) : images.length == 0 ? (
        <div class="center">
          We could not find any images for you. Please contact us at{" "}
          <a href="mailto:info@butlerdadsclub.org">info@butlerdadsclub.org</a>
        </div>
      ) : (
        <div class="photos-container">
          {images.map((image, idx) => (
            <div
              onClick={!isMobile ? () =>
                downloadImage(
                  `${url}${image.image_url}`,
                  `Father_Son_Frisbee_Golf_2023_${idx}.jpg`
                )
                : undefined
              }
              class="photo-container"
            >
              {" "}
              <img
                class="photo"
                src={`${url}${image.image_url}`}
                key={image.image_url}
              />
            </div>
          ))}
        </div>
      )}
      {downloading ? (
        <div class="downloading">Downloading</div>
      ) : (
        <div style={{ display: "none" }}></div>
      )}
    </div>
  );
}

export default App;
